<script>
import { mapActions, mapGetters } from 'vuex'
import { TYPES as ATECO_CODE_TYPES } from '../store'

export default {
  name: 'RiskLevelMixin',
  created () {
    if (!this.riskLevelList || (this.riskLevelList && this.riskLevelList.length === 0)) {
      this.getRisklevellist()
    }
  },
  computed: {
    ...mapGetters({
      riskLevelList: ATECO_CODE_TYPES.GENERIC.miscellaneous.atecocode.LIST.GETTERS.riskLevelList
    })
  },
  methods: {
    ...mapActions({
      getRisklevellist: ATECO_CODE_TYPES.GENERIC.miscellaneous.atecocode.LIST.ACTIONS.getRisklevellist
    }),
    selectRiskLevel (item) {
      this.$set(this.form, 'risk_level', item.value)
      this.$set(this.form, 'risk_level_name', item.name)
    },
    clearRiskLevel () {
      this.$set(this.form, 'risk_level', null)
      this.$set(this.form, 'risk_level_name', null)
    }
  }
}
</script>

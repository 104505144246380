<template>
  <panel :title="title" noButton="true" v-has-perms="rolePerm">
    <div>
      <b-form>
        <b-form-row>
          <form-thux-horizontal-input
            :validator="$v.form.code"
            :external-errors="errors['code']"
            :label-form="'Code' | translate"
            class-form="col-lg-6 mb-2"
            type="text"
            :value="form.code"
            :disabled="!formEditable"
            @change="$set(form, 'code', $event)"
          />
          <form-thux-horizontal-input
            :validator="$v.form.name"
            :external-errors="errors['name']"
            :label-form="'Name' | translate"
            class-form="col-lg-6 mb-2"
            type="text"
            :value="form.name"
            :disabled="!formEditable"
            @change="$set(form, 'name', $event)"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-select
            :validator="$v.form.risk_level"
            :external-errors="errors['risk_level']"
            :label-form="'Risk level' | translate"
            class-form="col-lg-6 mb-2"
            :disabled="!formEditable"
            label-search="name"
            :value="form.risk_level ? { value: form.risk_level, name: form.risk_level_name } : null"
            :placeholder="$t('Select an option')"
            :options="riskLevelListOptions"
            @select="selectRiskLevel"
            @clear="clearRiskLevel"
          />
          <form-thux-horizontal-input
            :validator="$v.form.note"
            :external-errors="errors['note']"
            :label-form="'Note' | translate"
            class-form="col-lg-6 mb-2"
            type="text"
            :value="form.note"
            :disabled="!formEditable"
            @change="$set(form, 'note', $event)"
          />
        </b-form-row>
      </b-form>
      <div class="mt-3 f-w-600 d-flex justify-content-end">
        <b-button
          class="mr-3"
          variant="default"
          @click.prevent="formEditable ? cancel() : closeForm()">
          {{ formEditable ? 'Cancel' : 'Close' | translate }}
        </b-button>
        <b-button
          variant="primary"
          v-has-perms="editRolePerm"
          @click.prevent="formEditable ? onSubmit() : makeFormEditable()"
          :disabled="$v.form.$invalid">
          {{ formEditable ? 'Save' : 'Edit' | translate }}
        </b-button>
      </div>
    </div>
  </panel>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import { TYPES as ATECO_CODE_TYPES } from './store'

import ThuxDetailPanelMixin from '../../../components/thux-detail-panel/ThuxDetailPanelMixin'
import RiskLevelMixin from './mixins/RiskLevelMixin'
import PopulateSelectMixin from '@/components/PopulateSelectMixin'

export default {
  name: 'AtecoCodeEdit',
  mixins: [ThuxDetailPanelMixin, RiskLevelMixin, PopulateSelectMixin],
  data () {
    return {
      pageName: 'Ateco code',
      rolePerm: 'miscellaneous_atecocode_retrieve',
      editRolePerm: 'miscellaneous_atecocode_update'
    }
  },
  computed: {
    ...mapGetters({
      ...ATECO_CODE_TYPES.GENERIC.miscellaneous.atecocode.DETAIL.GETTERS
    }),
    riskLevelListOptions () {
      return this.prepareListForSelect(this.riskLevelList, 'id', ['name'])
    }
  },
  components: { },
  methods: {
    ...mapActions({
      ...ATECO_CODE_TYPES.GENERIC.miscellaneous.atecocode.DETAIL.ACTIONS
    })
  },
  validations: {
    form: {
      code: { required },
      name: { required },
      risk_level: { required },
      note: {}
    }
  }
}
</script>

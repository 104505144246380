<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as ATECO_CODE_TYPES } from './store'
import { ROUTES as ATECO_CODE_ROUTES } from './router'
import { hasPerm } from '@/components/PermissionHelper'

import ThuxListMixin from '../../../components/thux-list/ThuxListMixin'

import AtecoCodeTable from './AtecoCodeTable'
import AtecoCodeCommonFilters from './AtecoCodeCommonFilters'
import AtecoCodeEdit from './AtecoCodeEdit.vue'

export default {
  name: 'AtecoCodeList',
  mixins: [ThuxListMixin],
  components: {
    'component-table': AtecoCodeTable,
    'common-filters': AtecoCodeCommonFilters,
    'component-detail': AtecoCodeEdit,
    'component-edit': AtecoCodeEdit
  },
  data () {
    return {
      ATECO_CODE_ROUTES,
      title: this.$t('ATECO Codes'),
      rolePerm: ['miscellaneous_atecocode_list'],
      actionEnablePermission: ['miscellaneous_atecocode_enable'],
      actionDisablePermission: ['miscellaneous_atecocode_disable'],
      searchFields: [
        { key: 'filter__code__icontains', placeholder: this.$t('Code'), type: 'text', col: 6 },
        { key: 'filter__name__icontains', placeholder: this.$t('Name'), type: 'text', col: 6 },
        { key: 'filter__risk_level__name__icontains', placeholder: this.$t('Risk level'), type: 'text', col: 6 }
      ]
    }
  },
  computed: {
    ...mapGetters({
      ...ATECO_CODE_TYPES.GENERIC.miscellaneous.atecocode.LIST.GETTERS
    }),
    showList () {
      return this.$route.name === this.ATECO_CODE_ROUTES.ATECO_CODE_LIST
    },
    canSeeActionSelect () {
      return true
    },
    canAddInstance () {
      return hasPerm('miscellaneous_atecocode_create')
    }
  },
  methods: {
    ...mapMutations({
      setSelectedList: ATECO_CODE_TYPES.GENERIC.miscellaneous.atecocode.LIST.MUTATIONS.setSelectedList,
      setSelectAll: ATECO_CODE_TYPES.GENERIC.miscellaneous.atecocode.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...ATECO_CODE_TYPES.GENERIC.miscellaneous.atecocode.LIST.ACTIONS
    })
  }
}
</script>
